import React from 'react';
import axios from 'axios';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import HomePage from '../components/dashboard/HomePage';
import { ENDPOINT, CANONICAL_URL } from '../api/constants';

function Home({ data }) {
  return (
    <>
      <NextSeo
        title='Login | Play Online Casino & Games | Bleon77'
        description="Bleon77 is India's Best Online Casino - offering a wide range of games such as Slots, Live Dealer Games (Baccarat, Blackjack), Table Games, Roulette and so much more. We're your go-to destination for the best online casino games."
        canonical={`${CANONICAL_URL}/login`}
      />
      <HomePage data={data} />
    </>
  );
}

export async function getServerSideProps({ req, locale }) {
  // const { userId, accessToken } = req.cookies;

  const options = {
    method: 'GET',
    url: `${ENDPOINT}/branches`,
    params: { _id: process.env.BRANCH_ID },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  let branchData;
  let banners;

  // get data
  await Promise.all([
    // get branch info
    new Promise((resolve) => axios.request(options)
      .then((result) => resolve(result?.data))
      .catch((err) => resolve(err))),
  ])
    .then(async ([branchInfo]) => {
      banners = branchInfo?.data?.[0]?.banners || [];

      branchData = branchInfo?.data?.[0] || [];
    });
  // get data

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: {
        ...branchData,
        branchId: process.env.BRANCH_ID,
        banners,
      },
    },
  };
}

export default Home;
